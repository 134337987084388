import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { CircularProgress } from "@mui/material";
import CustomButton from "../utils/customButton";
import {
  IconBrandYoutube,
  IconAnalyze,
  IconClick,
  IconTypography,
  IconClock,
  IconLayoutDashboard,
  IconPalette,
  IconStarFilled,
  IconWorld,
  IconAB,
  IconHelp,
  IconChevronDown,
} from "@tabler/icons-react";
import Functions from "../../callable/functions";
import ReactMarkdown from "react-markdown";

export default function ScriptAnalyzer() {
  const [title, setTitle] = useState("");
  const [script, setScript] = useState("");
  const [analysis, setAnalysis] = useState<string | null>(null);
  const [analyzing, setAnalyzing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const pageTitle =
    "YouTube Script Analyzer | Free AI-Powered Script Analysis Tool";
  const pageDescription =
    "Analyze your YouTube video scripts with our free AI-powered tool. Get professional feedback on your script's structure, hook, storytelling, and engagement potential to create better content.";

  const handleAnalyze = async () => {
    if (!title.trim() || !script.trim()) {
      setError("Please provide both a title and script");
      return;
    }

    setAnalyzing(true);
    setError(null);
    try {
      const functions = new Functions();
      const result = await functions.analyzeVideoScript({
        title: title.trim(),
        script: script.trim(),
      });

      if (result.data.success) {
        setAnalysis(result.data.analysis);
      } else {
        setError("Failed to analyze script. Please try again.");
      }
    } catch (error) {
      console.error("Error analyzing script:", error);
      setError("An error occurred while analyzing the script");
    } finally {
      setAnalyzing(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {/* <meta property="og:image" content="/images/script-analyzer-preview.jpg" /> */}

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        {/* <meta name="twitter:image" content="/images/script-analyzer-preview.jpg" /> */}

        {/* Additional SEO tags */}
        <meta
          name="keywords"
          content="YouTube script analysis, video script analyzer, content creation tools, YouTube SEO, video optimization, script writing, content strategy"
        />
        <link rel="canonical" href="https://yougenie.co/script-analyzer" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebApplication",
            name: "YouTube Script Analyzer",
            description: pageDescription,
            applicationCategory: "Content Creation Tool",
            operatingSystem: "Web Browser",
            offers: {
              "@type": "Offer",
              price: "0",
              priceCurrency: "USD",
            },
          })}
        </script>
      </Helmet>

      <div className="min-h-screen bg-[#0B0B0F] text-white font-custom">
        <header className="w-full max-w-5xl flex justify-between items-center px-4 py-4 mx-auto">
          <a href="/">
            <img
              src="/images/logo.svg"
              alt="YouGenie Logo"
              className="h-6 sm:h-8 cursor-pointer"
            />
          </a>
        </header>

        {/* Add Marketing Banner */}
        <div className="bg-gradient-to-r from-red-500 to-red-600 text-white">
          <div className="max-w-5xl mx-auto px-4 py-3 flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <span className="text-sm sm:text-base">
                Want AI-powered thumbnail generation? Try ThumbGenie for free!
              </span>
            </div>
            <a
              href="/thumb-genie"
              className="text-sm bg-white text-red-600 px-4 py-1 rounded-full font-semibold hover:bg-gray-100 transition-colors duration-200"
            >
              Try it!
            </a>
          </div>
        </div>

        <main className="max-w-5xl mx-auto px-4 py-8">
          {/* Title and Description */}
          <h1 className="text-2xl sm:text-4xl font-bold mb-4">
            YouTube Script Analyzer
          </h1>
          <p className="text-gray-400 mb-8">
            Get professional analysis of your YouTube video script. Our
            AI-powered tool evaluates your script's structure, hook,
            storytelling, and more to help you create more engaging content.
          </p>

          {/* How It Works Section */}
          <div className="mb-12">
            <h2 className="text-xl sm:text-2xl font-bold mb-4">How It Works</h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-5 gap-6">
              {[
                {
                  step: 1,
                  title: "Enter Your Video Title",
                  description:
                    "Start by providing your video's title to set the context for analysis.",
                },
                {
                  step: 2,
                  title: "Paste Your Script",
                  description:
                    "Copy and paste your video script into the editor.",
                },
                {
                  step: 3,
                  title: "AI-Powered Analysis",
                  description:
                    "Our AI evaluates key elements, including hook strength, storytelling quality, structure, and more!",
                },
                {
                  step: 4,
                  title: "Get Results",
                  description:
                    "Receive a detailed breakdown of strengths and areas for improvement.",
                },
                {
                  step: 5,
                  title: "Revise and Improve",
                  description:
                    "Use the feedback to refine your script and ensure it's optimized for maximum impact.",
                },
              ].map((step) => (
                <div key={step.step} className="bg-[#1A1A1F] p-6 rounded-lg">
                  <div className="flex items-center justify-center w-12 h-12 bg-red-500 rounded-full mb-4">
                    <span className="text-xl font-bold">{step.step}</span>
                  </div>
                  <h3 className="font-bold mb-2">{step.title}</h3>
                  <p className="text-gray-400 text-sm">{step.description}</p>
                </div>
              ))}
            </div>
          </div>

          {/* Main Analysis Section */}
          <div className="space-y-6 mb-12">
            {/* Input Section */}
            <div className="w-full space-y-6">
              <div>
                <label className="block text-sm font-bold mb-2">
                  Video Title
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter your video title"
                  className="w-full bg-[#1A1A1F] border border-gray-700 rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:border-red-500"
                />
              </div>

              <div>
                <label className="block text-sm font-bold mb-2">
                  Video Script
                </label>
                <textarea
                  value={script}
                  onChange={(e) => setScript(e.target.value)}
                  placeholder="Paste your video script here..."
                  rows={12}
                  className="w-full bg-[#1A1A1F] border border-gray-700 rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:border-red-500"
                />
              </div>

              {error && <div className="text-red-500 text-sm">{error}</div>}

              <div className="flex justify-end">
                <CustomButton
                  onClick={handleAnalyze}
                  disabled={analyzing || !title.trim() || !script.trim()}
                  startIcon={
                    analyzing ? (
                      <CircularProgress size={20} />
                    ) : (
                      <IconAnalyze size={20} />
                    )
                  }
                >
                  {analyzing ? "Analyzing..." : "Analyze Script"}
                </CustomButton>
              </div>
            </div>

            {/* Results Section */}
            <div className="w-full">
              <div className="bg-[#1A1A1F] rounded-lg p-6">
                <h2 className="text-xl font-bold mb-4">Analysis Results</h2>
                {analyzing ? (
                  <div className="flex flex-col items-center justify-center space-y-4 py-12">
                    <CircularProgress color="inherit" size={40} />
                    <p className="text-gray-400">Analyzing your script...</p>
                  </div>
                ) : analysis ? (
                  <div className="prose prose-invert max-w-none">
                    <ReactMarkdown className="text-gray-300">
                      {analysis}
                    </ReactMarkdown>
                  </div>
                ) : (
                  <div className="text-center py-12 text-gray-400">
                    <IconBrandYoutube
                      size={48}
                      className="mx-auto mb-4 text-gray-500"
                    />
                    <p>
                      Enter your video title and script to get a detailed
                      analysis
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Marketing Benefits Section */}
          <section className="mt-12 sm:mt-16 w-full mx-auto">
            <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
              Why Use Our Script Analyzer?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[
                {
                  title: "Perfect Your Hook",
                  description:
                    "Ensure your video grabs attention within the first 5 seconds.",
                  icon: (
                    <IconClick
                      size={32}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
                {
                  title: "Refine Storytelling",
                  description:
                    "Identify areas to enhance narrative flow and emotional connection.",
                  icon: (
                    <IconTypography
                      size={32}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
                {
                  title: "Optimize for Retention",
                  description:
                    "Get tips to keep viewers engaged from start to finish.",
                  icon: (
                    <IconClock
                      size={32}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
                {
                  title: "Improve Structure",
                  description:
                    "Balance pacing and transitions for smoother delivery.",
                  icon: (
                    <IconLayoutDashboard
                      size={32}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
                {
                  title: "Actionable Feedback",
                  description:
                    "Receive detailed suggestions tailored to your niche and audience.",
                  icon: (
                    <IconPalette
                      size={32}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
              ].map((benefit, index) => (
                <div
                  key={index}
                  className="bg-[#1A1A1F] p-6 rounded-lg transform transition-all duration-300 hover:scale-105 hover:bg-[#282830] cursor-pointer"
                >
                  <div className="flex justify-center mb-4">{benefit.icon}</div>
                  <h3 className="text-lg font-bold mb-2">{benefit.title}</h3>
                  <p className="text-gray-400 text-sm">{benefit.description}</p>
                </div>
              ))}
            </div>
          </section>

          {/* Tips Section */}
          <section className="mt-12 sm:mt-16 w-full mx-auto">
            <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
              Tips for Writing Scripts That Keep Viewers Hooked
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
              {[
                {
                  tip: "Start with a Strong Hook: pose an intriguing question or make a bold statement in the first few seconds.",
                  icon: (
                    <IconClick
                      size={24}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
                {
                  tip: "Keep It Conversational: write as if you're speaking directly to your audience.",
                  icon: (
                    <IconTypography
                      size={24}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
                {
                  tip: "Use Storytelling Techniques: build tension, create emotional connections, and resolve with impactful takeaways.",
                  icon: (
                    <IconStarFilled
                      size={24}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
                {
                  tip: "Focus on Value: ensure every part of your script serves a purpose and delivers value to your audience.",
                  icon: (
                    <IconWorld
                      size={24}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
                {
                  tip: "Test and Iterate: continuously refine your script based on audience feedback and analytics.",
                  icon: (
                    <IconAB size={24} className="text-red-500" stroke={1.5} />
                  ),
                },
              ].map((tip, index) => (
                <div key={index} className="bg-[#1A1A1F] p-6 rounded-lg">
                  <div className="flex justify-center mb-4">{tip.icon}</div>
                  <p className="text-gray-400 text-sm text-center">{tip.tip}</p>
                </div>
              ))}
            </div>
          </section>

          {/* FAQ Section */}
          <section className="mt-12 sm:mt-16 w-full mx-auto mb-12">
            <div className="flex items-center gap-3 mb-6 sm:mb-8">
              <IconHelp size={24} className="text-red-500" stroke={1.5} />
              <h2 className="text-xl sm:text-3xl font-bold font-custom">
                Frequently Asked Questions
              </h2>
            </div>
            <div className="space-y-4">
              {[
                {
                  question: "What type of scripts can I analyze?",
                  answer:
                    "The tool works with scripts for any type of YouTube video, including tutorials, vlogs, reviews, and storytelling content.",
                },
                {
                  question: "Can the AI suggest improvements to my hook?",
                  answer:
                    "Yes, the analyzer evaluates the effectiveness of your hook and offers suggestions for improvement.",
                },
                {
                  question: "Is the analysis suitable for beginners?",
                  answer:
                    "Absolutely! The tool provides clear and actionable feedback for creators at any skill level.",
                },
                {
                  question: "Will the tool help me improve viewer retention?",
                  answer:
                    "Yes, the AI identifies areas in your script where engagement might drop and suggests fixes to keep viewers watching.",
                },
                {
                  question: "Can I use this on scripts for non-YouTube videos?",
                  answer:
                    "While optimized for YouTube, the tool can analyze scripts for any video platform, like TikTok or Instagram.",
                },
                {
                  question: "Is the analysis personalized to my audience?",
                  answer:
                    "Yes, the AI considers your video title and script to tailor feedback to your specific niche and goals.",
                },
                {
                  question: "How do I use the results to improve my script?",
                  answer:
                    "Each analysis includes actionable suggestions that you can implement immediately to refine your content.",
                },
                {
                  question: "Can I analyze multiple scripts?",
                  answer:
                    "Yes, you can analyze as many scripts as you'd like, one at a time.",
                },
                {
                  question: "Is the tool free to use?",
                  answer: "Yes, the YouTube Script Analyzer is 100% free.",
                },
              ].map((faq, index) => (
                <div
                  key={index}
                  className="bg-[#1A1A1F] rounded-lg overflow-hidden"
                >
                  <button
                    className="w-full p-4 flex justify-between items-center text-left"
                    onClick={() =>
                      setOpenIndex(openIndex === index ? null : index)
                    }
                  >
                    <h3 className="text-lg font-medium">{faq.question}</h3>
                    <IconChevronDown
                      size={20}
                      className={`transform transition-transform duration-300 ${
                        openIndex === index ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  <div
                    className={`overflow-hidden transition-all duration-300 ${
                      openIndex === index ? "max-h-48" : "max-h-0"
                    }`}
                  >
                    <p className="text-gray-400 p-4 pt-0 text-left">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
