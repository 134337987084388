import { getFunctions, httpsCallable } from "firebase/functions";
import { youtube_v3 } from "googleapis";

interface Metric {
  current: number;
  change: number;
  percentage: number;
}

interface ChannelMetrics {
  subscribers: Metric;
  views: Metric;
  videos: Metric;
}

interface VideoMetrics {
  id: string;
  title: string;
  views: number;
  vph: number;
  outlierScore: number;
  isShort: boolean;
  publishedAt: string;
}

export default class Functions {
  fetchCommentThreads = httpsCallable<
    { userId: string; videoId: string; nextPageToken: string | undefined },
    {
      commentThreads: youtube_v3.Schema$CommentThread[];
      nextPageToken: string | undefined;
    }
  >(getFunctions(), "fetchCommentThreads");

  replyCommentThread = httpsCallable<
    {
      userId: string;
      commentId: string;
      channelId: string;
      reply: string;
      comment: string;
    },
    {
      success: boolean;
    }
  >(getFunctions(), "replyCommentThread");

  generateReply = httpsCallable<
    {
      userId: string;
      channelId: string;
      comment: youtube_v3.Schema$CommentSnippet;
    },
    {
      success: boolean;
      reply: string;
    }
  >(getFunctions(), "generateReply");

  addChannel = httpsCallable<
    { userId: string; channelIdentifier: string },
    { success: boolean }
  >(getFunctions(), "addChannel");

  fetchChannelMetrics = httpsCallable<
    { userId: string; channelId: string; timePeriod: string },
    ChannelMetrics
  >(getFunctions(), "fetchChannelMetrics");

  fetchVideoMetrics = httpsCallable<
    { userId: string; channelId: string; timePeriod: string },
    VideoMetrics[]
  >(getFunctions(), "fetchVideoMetrics");

  fetchLatestVideos = httpsCallable<
    { userId: string; channelId: string; maxResults?: number },
    { videos: youtube_v3.Schema$Video[] }
  >(getFunctions(), "fetchLatestVideos");

  analyzeVideoComments = httpsCallable<
    { videoId: string; prompt: string },
    { success: boolean; analysis: string }
  >(getFunctions(), "analyzeVideoComments");

  generateThumbnail = httpsCallable<
    {
      userId: string;
      modelIdentifier: string;
      prompt: string;
      image?: string;
      guidanceScale?: number;
    },
    {
      success: boolean;
      generationIds: string[];
      outputUrls: string[];
      error?: string;
    }
  >(getFunctions(), "generateThumbnail", { timeout: 540000 });

  searchVideos = httpsCallable<
    {
      userId: string;
      channelId: string;
      mode: string;
      searchTerm?: string;
      videoCount?: number;
      startDate?: string;
      endDate?: string;
    },
    {
      videos: youtube_v3.Schema$Video[];
      totalResults?: number;
      resultsPerPage?: number;
    }
  >(getFunctions(), "searchVideos");

  getCampaignViewCount = httpsCallable<
    { userId: string; campaignId: string },
    { campaignId: string; totalViewCount: number }
  >(getFunctions(), "getCampaignViewCount");

  analyzeVideoScript = httpsCallable<
    { title: string; script: string },
    { success: boolean; analysis: string }
  >(getFunctions(), "analyzeVideoScript");
}
