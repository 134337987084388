import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../utils/customButton";
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithProvider } from "../../contexts/userContext";
import {
  IconCalendar,
  IconBrandGoogleFilled,
  IconPalette,
  IconBolt,
  IconCoin,
  IconChartBar,
  IconCheck,
  IconX,
  IconHelp,
  IconUserOff,
  IconUsers,
  IconBuildingStore,
  IconRocket,
  IconChevronDown,
} from "@tabler/icons-react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../utils/footer";
import { Helmet } from "react-helmet-async";

type Tool =
  | "ThumbGenie"
  | "Canva Magic"
  | "VidIQ"
  | "Adobe Express"
  | "Hotpot AI"
  | "Thumbnail.AI";

type ComparisonRow = {
  feature: string;
  supported?: Tool[];
  custom?: Record<Tool, string>;
};

const tools: Tool[] = [
  "ThumbGenie",
  "Canva Magic",
  "VidIQ",
  "Adobe Express",
  "Hotpot AI",
  "Thumbnail.AI",
];

const comparisonData: ComparisonRow[] = [
  {
    feature: "Custom Model Training",
    supported: ["ThumbGenie"],
  },
  {
    feature: "Scalability",
    supported: [
      "ThumbGenie",
      "Canva Magic",
      "VidIQ",
      "Adobe Express",
      "Hotpot AI",
      "Thumbnail.AI",
    ],
  },
  {
    feature: "Realism Adjustment",
    supported: ["ThumbGenie"],
  },
  {
    feature: "Advanced Customization",
    supported: ["ThumbGenie", "Canva Magic"],
  },
  {
    feature: "Pricing Model",
    custom: {
      ThumbGenie: "Pay-as-you-go",
      "Canva Magic": "Subscription",
      VidIQ: "Subscription",
      "Adobe Express": "Free with Paid Plans",
      "Hotpot AI": "Budget-Friendly",
      "Thumbnail.AI": "Free",
    },
  },
];

export default function ThumbGeniePublic() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithProvider(provider);
      navigate("/app/thumb-genie");
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const steps = [
    {
      label: "Select a Model",
      content:
        "Choose from your trained models, each tailored to a specific style or theme for your channel.",
    },
    {
      label: "Enter Your Prompt",
      content:
        "Describe the thumbnail you want to create. You can use the model's base prompt as a starting point.",
    },
    {
      label: "Add a Reference Image (Optional)",
      content:
        "You can use a YouTube video URL to add a reference image, guiding the AI to create similar styles or compositions.",
    },
    {
      label: "Adjust Realism Scale",
      content:
        "Choose between Realistic, Balanced Realistic, Balanced Creative, or Highly Creative to fine-tune your thumbnail's style.",
    },
    {
      label: "Generate and Review",
      content:
        "Click 'Generate' to create your thumbnail. Review the results and use them as-is or as inspiration for further refinement.",
    },
  ];

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  const FAQSection = () => {
    const [openIndex, setOpenIndex] = React.useState<number | null>(null);

    const faqs = [
      {
        question: "What is the ideal thumbnail size for YouTube?",
        answer:
          "1280 x 720 pixels. This size ensures compatibility with YouTube's display requirements, maintaining clarity and optimal resolution across all devices.",
      },
      {
        question: "How long does it take to generate a thumbnail?",
        answer: "In just seconds, you'll receive multiple design options.",
      },
      {
        question: "How does custom model training work?",
        answer:
          "Upload 10-20 reference images from your existing thumbnails, and our AI learns your unique style to generate on-brand designs.",
      },
      {
        question: "What are the different realism settings?",
        answer:
          "Choose between Realistic, Balanced Realistic, Balanced Creative, or Highly Creative to fine-tune your thumbnail's style and match your video's tone.",
      },
      {
        question: "Can I edit the thumbnails after generation?",
        answer:
          "Yes, our built-in editor allows you to fine-tune details like text, colors, and overlays after generation.",
      },
      {
        question: "Is ThumbGenie suitable for faceless channels?",
        answer:
          "Absolutely! ThumbGenie is perfect for faceless channels, allowing you to generate professional thumbnails without relying on specific faces.",
      },
    ];

    return (
      <section className="mt-12 sm:mt-16 w-full mx-auto mb-12">
        <div className="flex items-center gap-3 mb-6 sm:mb-8">
          <IconHelp size={24} className="text-red-500" />
          <h2 className="text-xl sm:text-3xl font-bold font-custom">
            Frequently Asked Questions
          </h2>
        </div>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-[#1A1A1F] rounded-lg overflow-hidden"
            >
              <button
                className="w-full p-4 flex justify-between items-center text-left"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <h3 className="text-lg font-medium">{faq.question}</h3>
                <IconChevronDown
                  size={20}
                  className={`transform transition-transform duration-300 ${
                    openIndex === index ? "rotate-180" : ""
                  }`}
                />
              </button>
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  openIndex === index ? "max-h-48" : "max-h-0"
                }`}
              >
                <p className="text-gray-400 p-4 pt-0 text-left">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: faqs.map((faq) => ({
              "@type": "Question",
              name: faq.question,
              acceptedAnswer: {
                "@type": "Answer",
                text: faq.answer,
              },
            })),
          })}
        </script>
      </section>
    );
  };

  const pageTitle = "ThumbGenie | Best AI Thumbnail Maker for YouTube Creators";
  const pageDescription =
    "Create high-converting YouTube thumbnails with ThumbGenie's AI-powered thumbnail generator. Custom model training, faceless channel support, and professional designs in seconds.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yougenie.co/thumb-genie" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://yougenie.co/thumb-genie" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />

        {/* Additional SEO tags */}
        <link rel="canonical" href="https://yougenie.co/thumb-genie" />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "SoftwareApplication",
          name: "ThumbGenie",
          applicationCategory: "DesignApplication",
          operatingSystem: "Web",
          description: "AI-powered thumbnail generator for YouTube creators",
        })}
      </script>
      <div className="min-h-screen text-white flex flex-col font-custom relative">
        <header
          role="banner"
          className="w-full max-w-5xl flex justify-between items-center px-4 sm:px-8 py-4 mx-auto"
        >
          <a href="/" aria-label="Return to YouGenie homepage">
            <img
              src="/images/logo.svg"
              alt="YouGenie Logo"
              className="h-6 sm:h-8"
              width="120"
              height="32"
            />
          </a>
        </header>

        <main role="main" className="flex-1">
          <article className="text-center mt-8 sm:mt-12 w-full max-w-5xl mx-auto px-4">
            <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-4 sm:mb-6">
              Best AI Thumbnail Maker for YouTube
            </h1>
            <p className="mb-6 text-gray-300 text-sm sm:text-base max-w-3xl mx-auto">
              ThumbGenie is the #1 AI thumbnail maker for creating
              high-converting, personalized YouTube thumbnails automatically.
              Our powerful thumbnail generator customizes designs to match your
              channel's unique style, boosting click-through rates while saving
              you time and money.
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
              <CustomButton
                variant="contained"
                startIcon={<IconCalendar size={isMobile ? 18 : 20} />}
                onClick={() =>
                  window.open(
                    "https://calendar.app.google/mU2hf2jVRsCs5dBNA",
                    "_blank"
                  )
                }
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  "&:hover": { backgroundColor: theme.palette.primary.dark },
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                  padding: { xs: "8px 16px", sm: "10px 20px" },
                }}
              >
                Book a Demo
              </CustomButton>
              <CustomButton
                variant="contained"
                startIcon={<IconBrandGoogleFilled size={isMobile ? 18 : 20} />}
                onClick={handleSignIn}
                sx={{
                  backgroundColor: "#282828",
                  "&:hover": { backgroundColor: "#383838" },
                  fontSize: { xs: "0.875rem", sm: "1rem" },
                  padding: { xs: "8px 16px", sm: "10px 20px" },
                }}
              >
                Sign in with Google
              </CustomButton>
            </div>
            <div className="mt-8 sm:mt-12 w-full mx-auto">
              <div
                className="relative"
                style={{
                  paddingBottom: "56.25%",
                  borderRadius: "12px",
                  overflow: "hidden",
                }}
              >
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src="https://www.youtube.com/embed/wBPgsAMoebg?autoplay=1&mute=0&controls=1&rel=0&modestbranding=1"
                  title="YouGenie Demo Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            {/* Thumbnail Examples section */}
            <section className="mt-12 sm:mt-16 w-full mx-auto">
              <h2 className="text-base sm:text-lg font-bold mb-6 sm:mb-8 font-custom text-gray-400">
                powered by YouGenie
              </h2>
              <Slider {...carouselSettings}>
                {[
                  {
                    src: "/images/mr-beast-1.jpg",
                    channel: "MrBeast",
                    alt: "MrBeast YouTube thumbnail created with ThumbGenie AI",
                  },
                  {
                    src: "/images/jake-paul-1.jpg",
                    channel: "Jake Paul",
                    alt: "Jake Paul boxing thumbnail generated by ThumbGenie",
                  },
                  {
                    src: "/images/meditarsons-1.jpg",
                    channel: "MeditarSons",
                    alt: "MeditarSons gaming thumbnail designed with ThumbGenie",
                  },
                  {
                    src: "/images/mr-beast-2.png",
                    channel: "MrBeast",
                    alt: "MrBeast YouTube thumbnail created with ThumbGenie AI",
                  },
                  {
                    src: "/images/jake-paul-2.png",
                    channel: "Jake Paul",
                    alt: "Jake Paul boxing thumbnail generated by ThumbGenie",
                  },
                  {
                    src: "/images/meditarsons-2.png",
                    channel: "MeditarSons",
                    alt: "MeditarSons gaming thumbnail designed with ThumbGenie",
                  },
                ].map((image, index) => (
                  <div key={index} className="px-2">
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="w-full h-auto rounded-lg"
                      loading={index > 0 ? "lazy" : undefined}
                    />
                    <p className="mt-4 text-center text-gray-300 text-sm sm:text-base">
                      {image.channel}'s channel
                    </p>
                  </div>
                ))}
              </Slider>
            </section>

            <section className="mt-12 sm:mt-16 w-full mx-auto">
              <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
                Why Choose ThumbGenie?
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {[
                  {
                    title: "Customizable Styles",
                    description:
                      "Train AI models based on your existing thumbnails for consistent branding.",
                    icon: (
                      <IconPalette
                        size={32}
                        className="text-red-500"
                        stroke={1.5}
                      />
                    ),
                  },
                  {
                    title: "Time Efficiency",
                    description:
                      "Generate multiple thumbnails in seconds, saving hours of design work.",
                    icon: (
                      <IconBolt
                        size={32}
                        className="text-red-500"
                        stroke={1.5}
                      />
                    ),
                  },
                  {
                    title: "Cost-Effective",
                    description:
                      "Reduce the need for expensive graphic designers or software.",
                    icon: (
                      <IconCoin
                        size={32}
                        className="text-red-500"
                        stroke={1.5}
                      />
                    ),
                  },
                  {
                    title: "Scalable Solution",
                    description:
                      "Ideal for faceless channels and high-volume creators.",
                    icon: (
                      <IconChartBar
                        size={32}
                        className="text-red-500"
                        stroke={1.5}
                      />
                    ),
                  },
                ].map((benefit, index) => (
                  <div
                    key={index}
                    className="bg-[#1A1A1F] p-6 rounded-lg transform transition-all duration-300 hover:scale-105 hover:bg-[#282830] cursor-pointer"
                  >
                    <div className="flex justify-center mb-4">
                      {benefit.icon}
                    </div>
                    <h3 className="text-lg font-bold mb-2">{benefit.title}</h3>
                    <p className="text-gray-400 text-sm">
                      {benefit.description}
                    </p>
                  </div>
                ))}
              </div>
            </section>

            <section className="mt-12 sm:mt-16 w-full mx-auto">
              <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
                What Our Users Say
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {[
                  {
                    quote:
                      "ThumbGenie has transformed how I create content. My CTR has improved by 35%!",
                    author: "Alex R.",
                    role: "Faceless YouTube Channel Creator",
                  },
                  {
                    quote:
                      "I've saved countless hours thanks to ThumbGenie. The custom models are a game-changer.",
                    author: "Maria S.",
                    role: "Tech Influencer",
                  },
                  {
                    quote:
                      "I love how easy it is to generate thumbnails that perfectly match my brand.",
                    author: "Jordan T.",
                    role: "Lifestyle YouTuber",
                  },
                ].map((testimonial, index) => (
                  <div key={index} className="bg-[#1A1A1F] p-6 rounded-lg">
                    <p className="text-gray-300 italic mb-4">
                      "{testimonial.quote}"
                    </p>
                    <p className="text-white font-bold">{testimonial.author}</p>
                    <p className="text-gray-400 text-sm">{testimonial.role}</p>
                  </div>
                ))}
              </div>
            </section>

            <section className="mt-12 sm:mt-16 w-full mx-auto">
              <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
                How ThumbGenie Compares
              </h2>
              <div className="bg-[#1A1A1F] p-6 rounded-lg">
                <div className="overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr className="border-b border-gray-800">
                        <th className="p-4">Feature</th>
                        {tools.map((tool) => (
                          <th key={tool} className="p-4">
                            {tool}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {comparisonData.map((row, index) => (
                        <tr key={index} className="border-b border-gray-800">
                          <td className="p-4">{row.feature}</td>
                          {tools.map((tool) => (
                            <td key={tool} className="p-4">
                              {row.custom ? (
                                row.custom[tool]
                              ) : row.supported?.includes(tool) ? (
                                <IconCheck
                                  className="text-green-500"
                                  size={20}
                                />
                              ) : (
                                <IconX className="text-red-500" size={20} />
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            {/* How it Works section */}
            <section className="mt-12 sm:mt-16 w-full mx-auto">
              <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
                How it Works
              </h2>
              <Stepper
                orientation="vertical"
                sx={{
                  "& .MuiStepIcon-root": {
                    color: "#282828",
                    "&.Mui-active, &.Mui-completed": {
                      color: "#FF0000",
                    },
                  },
                  "& .MuiStepIcon-text": {
                    fill: "#FFFFFF",
                    fontWeight: "bold",
                    fontSize: "0.75rem",
                  },
                  "& .MuiStepLabel-label": {
                    fontFamily: "'YouTubeSansRegular', sans-serif",
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  },
                  "& .MuiStepContent-root": {
                    borderLeft: "1px solid rgba(255, 255, 255, 0.2)",
                  },
                }}
              >
                {steps.map((step, index) => (
                  <Step active={true} key={step.label}>
                    <StepLabel>
                      <span className="text-white font-custom">
                        {step.label}
                      </span>
                    </StepLabel>
                    <StepContent>
                      <p className="text-gray-300 mb-4 text-left font-custom text-sm sm:text-base">
                        {step.content}
                      </p>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </section>

            <section className="mt-12 sm:mt-16 w-full mx-auto">
              <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
                Who Can Benefit?
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                {[
                  {
                    title: "Faceless Channels",
                    description:
                      "Generate professional thumbnails without relying on specific faces.",
                    icon: (
                      <IconUserOff
                        size={24}
                        className="text-red-500"
                        stroke={1.5}
                      />
                    ),
                  },
                  {
                    title: "Scalable Creators",
                    description:
                      "Perfect for those producing large volumes of content.",
                    icon: (
                      <IconUsers
                        size={24}
                        className="text-red-500"
                        stroke={1.5}
                      />
                    ),
                  },
                  {
                    title: "Established Brands",
                    description:
                      "Maintain a consistent visual identity across videos.",
                    icon: (
                      <IconBuildingStore
                        size={24}
                        className="text-red-500"
                        stroke={1.5}
                      />
                    ),
                  },
                  {
                    title: "Beginner YouTubers",
                    description:
                      "Stand out with professional designs without advanced skills.",
                    icon: (
                      <IconRocket
                        size={24}
                        className="text-red-500"
                        stroke={1.5}
                      />
                    ),
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="bg-[#1A1A1F] p-6 rounded-lg transform transition-all duration-300 hover:scale-105 hover:bg-[#282830] cursor-pointer"
                  >
                    <div className="flex justify-center mb-4">{item.icon}</div>
                    <h3 className="font-bold mb-2">{item.title}</h3>
                    <p className="text-gray-400 text-sm">{item.description}</p>
                  </div>
                ))}
              </div>
            </section>

            <FAQSection />
          </article>
        </main>
        <Footer />
      </div>
    </>
  );
}
