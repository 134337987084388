import React from "react";
import { useNavigate } from "react-router-dom";
import {
  IconEdit,
  IconFileText,
  IconBrain,
  IconArrowRight,
  IconBrandYoutube,
} from "@tabler/icons-react";
import { Helmet } from "react-helmet-async";

export default function Tools() {
  const navigate = useNavigate();

  const tools = [
    {
      title: "YouTube Thumbnail Editor",
      description:
        "Free online thumbnail editor for YouTube creators. Add custom text with effects, overlay logos, and enhance your video thumbnails to increase click-through rates.",
      icon: <IconEdit size={32} className="text-red-500" stroke={1.5} />,
      path: "/thumbnail-editor",
      features: [
        "Add custom text with effects",
        "Overlay logos and watermarks",
        "Real-time preview",
        "No account required",
      ],
    },
    {
      title: "YouTube Script Analyzer",
      description:
        "Analyze your YouTube video scripts with our free AI-powered tool. Get professional feedback on your script's structure, hook, storytelling, and engagement potential.",
      icon: <IconFileText size={32} className="text-red-500" stroke={1.5} />,
      path: "/script-analyzer",
      features: [
        "AI-powered script analysis",
        "Engagement optimization tips",
        "Structure recommendations",
        "Hook effectiveness feedback",
      ],
    },
    {
      title: "YouTube Preview Tool",
      description:
        "Preview how your videos will appear in different YouTube layouts before publishing. Test thumbnails, titles, and channel branding.",
      icon: (
        <IconBrandYoutube size={32} className="text-red-500" stroke={1.5} />
      ),
      path: "/youtube-preview",
      features: [
        "Feed view preview",
        "Search results preview",
        "Suggested videos preview",
        "Real-time updates",
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Free YouTube Tools | YouGenie</title>
        <meta
          name="description"
          content="Access our collection of free tools for YouTube creators. Edit thumbnails, analyze scripts, and improve your content with our professional-grade tools."
        />
        <meta property="og:title" content="Free YouTube Tools | YouGenie" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yougenie.co/tools" />
        <link rel="canonical" href="https://yougenie.co/tools" />
      </Helmet>

      <div
        className="min-h-screen text-white flex flex-col font-custom"
        style={{
          backgroundColor: "#0B0B0F",
          backgroundImage: "url(/images/background-pattern.png)",
          backgroundPosition: "top center",
        }}
      >
        <header className="w-full max-w-5xl flex justify-between items-center px-8 py-4 mx-auto">
          <a href="/">
            <img
              src="/images/logo.svg"
              alt="YouGenie Logo"
              className="h-8 cursor-pointer"
            />
          </a>
        </header>

        <main className="flex-1 max-w-5xl mx-auto px-4 sm:px-8 py-8 w-full">
          <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-4 sm:mb-6">
            Free Tools for YouTube Creators
          </h1>
          <p className="text-gray-300 text-lg mb-12">
            Professional-grade tools to help you create better content,
            completely free. No account required.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {tools.map((tool, index) => (
              <div
                key={index}
                className="bg-[#1A1A1F] p-6 rounded-lg hover:bg-[#282830] transition-colors duration-300"
              >
                <div className="flex items-center gap-3 mb-4">
                  {tool.icon}
                  <h2 className="text-xl font-bold">{tool.title}</h2>
                </div>
                <p className="text-gray-400 mb-6">{tool.description}</p>
                <ul className="space-y-2 mb-6">
                  {tool.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center gap-2">
                      <IconBrain
                        size={16}
                        className="text-red-500 flex-shrink-0"
                      />
                      <span className="text-gray-300 text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => navigate(tool.path)}
                  className="w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition-colors duration-200 flex items-center justify-center gap-2"
                >
                  Try Now
                  <IconArrowRight size={20} />
                </button>
              </div>
            ))}
          </div>
        </main>
      </div>
    </>
  );
}
