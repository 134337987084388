declare global {
  interface Window {
    gtag?: (command: string, action: string, params?: any) => void;
  }
}

export const trackEvent = (eventName: string, eventData?: any) => {
  if (window.gtag) {
    window.gtag("event", eventName, eventData);
  }
};
