import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="w-full max-w-5xl mx-auto mt-12 sm:mt-16 py-8 text-center">
      <div className="flex flex-col items-center space-y-4">
        <a
          href="https://blog.yougenie.co"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-300 hover:text-white text-sm sm:text-base"
        >
          Blog
        </a>
        <a
          href="https://instagram.com/yougenie.co"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-300 hover:text-white text-sm sm:text-base"
        >
          Instagram
        </a>
        <a
          href="/thumbnail-editor"
          className="text-gray-300 hover:text-white text-sm sm:text-base"
        >
          Thumbnail Editor
        </a>
        <a
          href="/script-analyzer"
          className="text-gray-300 hover:text-white text-sm sm:text-base"
        >
          Script Analyzer
        </a>
        <a
          href="/privacy"
          className="text-gray-300 hover:text-white text-sm sm:text-base"
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
