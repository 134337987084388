import React, { useState } from "react";
import { IconUpload, IconX } from "@tabler/icons-react";
import { Helmet } from "react-helmet-async";

export default function YoutubePreview() {
  const [thumbnail, setThumbnail] = useState<string | null>(
    "/images/youtube-preview-default.jpg"
  );
  const [title, setTitle] = useState(
    "Nova Monetização que PODE chegar no YouTube em Breve!"
  );
  const [channelName, setChannelName] = useState(
    "Escola para YouTubers - Caíque Pereira"
  );
  const [channelAvatar, setChannelAvatar] = useState<string | null>(null);

  const handleThumbnailUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnail(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setChannelAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Helmet>
        <title>YouTube Preview Tool | Free YouTube Thumbnail Preview</title>
        <meta
          name="description"
          content="Preview how your YouTube video will appear in different layouts. Test thumbnails, titles, and channel branding before publishing."
        />
      </Helmet>

      <div className="min-h-screen bg-[#0B0B0F] text-white font-custom">
        <header className="w-full max-w-5xl mx-auto px-4 py-4">
          <a href="/">
            <img
              src="/images/logo.svg"
              alt="YouGenie Logo"
              className="h-6 sm:h-8"
            />
          </a>
        </header>

        <main className="max-w-5xl mx-auto px-4 py-8">
          <h1 className="text-2xl sm:text-4xl font-bold mb-4">
            YouTube Preview Tool
          </h1>
          <p className="text-gray-400 mb-8">
            Preview how your video will appear in different YouTube layouts
            before publishing. Test thumbnails, titles, and channel branding to
            optimize your video's presentation.
          </p>

          {/* Input Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
            <div>
              <label className="block text-sm font-medium mb-2">
                Upload Thumbnail (16:9)
              </label>
              <div className="border-2 border-dashed border-gray-700 rounded-lg p-4 text-center">
                {thumbnail ? (
                  <div className="relative">
                    <img
                      src={thumbnail}
                      alt="Thumbnail preview"
                      className="w-full rounded"
                    />
                    <button
                      onClick={() => setThumbnail(null)}
                      className="absolute top-2 right-2 bg-red-500 hover:bg-red-600 text-white p-1 rounded-full"
                    >
                      <IconX size={16} stroke={2} />
                    </button>
                  </div>
                ) : (
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleThumbnailUpload}
                    />
                    <IconUpload className="mx-auto mb-2" />
                    <span className="text-sm text-gray-400">
                      Click to upload thumbnail
                    </span>
                  </label>
                )}
              </div>
            </div>

            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium mb-2">
                  Video Title
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full bg-[#1A1A1F] border border-gray-700 rounded-lg p-3 text-white"
                  placeholder="Enter your video title"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">
                  Channel Name
                </label>
                <input
                  type="text"
                  value={channelName}
                  onChange={(e) => setChannelName(e.target.value)}
                  className="w-full bg-[#1A1A1F] border border-gray-700 rounded-lg p-3 text-white"
                  placeholder="Enter your channel name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">
                  Channel Avatar
                </label>
                <div className="border-2 border-dashed border-gray-700 rounded-lg p-4 text-center">
                  {channelAvatar ? (
                    <div className="relative inline-block">
                      <img
                        src={channelAvatar}
                        alt="Channel avatar"
                        className="w-12 h-12 rounded-full"
                      />
                      <button
                        onClick={() => setChannelAvatar(null)}
                        className="absolute -top-2 -right-2 bg-red-500 hover:bg-red-600 text-white p-1 rounded-full"
                      >
                        <IconX size={12} stroke={2} />
                      </button>
                    </div>
                  ) : (
                    <label className="cursor-pointer">
                      <input
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleAvatarUpload}
                      />
                      <IconUpload className="mx-auto mb-2" />
                      <span className="text-sm text-gray-400">
                        Click to upload avatar
                      </span>
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Preview Section */}
          <div className="space-y-8">
            <PreviewSection
              title="Feed Preview"
              thumbnail={thumbnail}
              videoTitle={title}
              channelName={channelName}
              channelAvatar={channelAvatar}
              type="feed"
            />

            <PreviewSection
              title="Search Results Preview"
              thumbnail={thumbnail}
              videoTitle={title}
              channelName={channelName}
              channelAvatar={channelAvatar}
              type="search"
            />

            <PreviewSection
              title="Suggested Videos Preview"
              thumbnail={thumbnail}
              videoTitle={title}
              channelName={channelName}
              channelAvatar={channelAvatar}
              type="suggested"
            />
          </div>
        </main>
      </div>
    </>
  );
}

interface PreviewSectionProps {
  title: string;
  thumbnail: string | null;
  videoTitle: string;
  channelName: string;
  channelAvatar: string | null;
  type: "feed" | "search" | "suggested";
}

const PreviewSection: React.FC<PreviewSectionProps> = ({
  title,
  thumbnail,
  videoTitle,
  channelName,
  channelAvatar,
  type,
}) => {
  const defaultAvatar = "/images/youtube-preview-avatar.jpg";
  const defaultThumbnail = "/images/youtube-preview-default.jpg";

  // Feed preview specific styles
  const feedStyles = {
    container: "w-[273px] h-[247.125px]",
    thumbnail: "w-[273px] h-[154px]",
    title: "text-[14px] leading-[20px] font-medium line-clamp-2 break-words",
    channelInfo:
      "text-[12px] leading-[18px] text-[#AAAAAA] mt-1 block truncate",
    metadata: "text-[12px] leading-[18px] text-[#AAAAAA] mt-1 block",
  };

  // Search preview specific styles
  const searchStyles = {
    container: "max-w-full lg:w-[1137px]",
    wrapper: "flex gap-4",
    thumbnailContainer: "w-[360px] shrink-0",
    infoContainer: "flex-1 mt-0 min-w-0",
    thumbnail: "w-[360px] h-[202px]",
    title: "text-[18px] leading-[26px] font-medium line-clamp-2 break-words",
    channelInfo:
      "text-[12px] leading-[18px] text-[#AAAAAA] mt-2 flex items-center gap-1 truncate",
  };

  // Suggested videos specific styles
  const suggestedStyles = {
    container: "w-[402px]",
    wrapper: "flex gap-2",
    thumbnailContainer: "w-[168px] shrink-0",
    thumbnail: "w-[168px] h-[94px]",
    infoContainer: "flex-1 min-w-0",
    title: "text-[14px] leading-[20px] font-medium line-clamp-2 break-words",
    metadata: "text-[12px] leading-[18px] text-[#AAAAAA] mt-1 truncate",
  };

  const getPreviewContent = () => {
    switch (type) {
      case "feed":
        return (
          <div className={feedStyles.container}>
            <div className="relative">
              <img
                src={thumbnail || defaultThumbnail}
                alt="Video thumbnail"
                className={`${feedStyles.thumbnail} rounded-lg object-cover`}
              />
              <span className="absolute bottom-1 right-1 bg-black bg-opacity-80 px-2 py-0.5 text-xs rounded text-white">
                0:00
              </span>
            </div>
            <div className="flex mt-3 gap-3">
              <img
                src={channelAvatar || defaultAvatar}
                alt="Channel avatar"
                className="w-9 h-9 rounded-full shrink-0"
              />
              <div>
                <h4 className={feedStyles.title}>
                  {videoTitle || "Video Title"}
                </h4>
                <span className={feedStyles.channelInfo}>
                  {channelName || "Channel Name"}
                </span>
                <span className={feedStyles.metadata}>
                  0 views • 0 hours ago
                </span>
              </div>
            </div>
          </div>
        );

      case "search":
        return (
          <div className={searchStyles.container}>
            <div className={searchStyles.wrapper}>
              <div className={searchStyles.thumbnailContainer}>
                <div className="relative">
                  <img
                    src={thumbnail || defaultThumbnail}
                    alt="Video thumbnail"
                    className={`${searchStyles.thumbnail} rounded-lg object-cover`}
                  />
                  <span className="absolute bottom-1 right-1 bg-black bg-opacity-80 px-2 py-0.5 text-xs rounded text-white">
                    0:00
                  </span>
                </div>
              </div>
              <div className={searchStyles.infoContainer}>
                <h4 className={searchStyles.title}>
                  {videoTitle || "Video Title"}
                </h4>
                <div className="text-[12px] leading-[18px] text-[#AAAAAA] mt-1">
                  0 views • 0 hours ago
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <img
                    src={channelAvatar || defaultAvatar}
                    alt="Channel avatar"
                    className="w-6 h-6 rounded-full"
                  />
                  <span className="text-[12px] leading-[18px] text-[#AAAAAA]">
                    {channelName || "Channel Name"}
                  </span>
                </div>
                <p className="text-[12px] leading-[18px] text-[#AAAAAA] mt-2 line-clamp-2">
                  Video description would appear here...
                </p>
              </div>
            </div>
          </div>
        );

      case "suggested":
        return (
          <div className={suggestedStyles.container}>
            <div className={suggestedStyles.wrapper}>
              <div className={suggestedStyles.thumbnailContainer}>
                <div className="relative">
                  <img
                    src={thumbnail || defaultThumbnail}
                    alt="Video thumbnail"
                    className={`${suggestedStyles.thumbnail} rounded-lg object-cover`}
                  />
                  <span className="absolute bottom-1 right-1 bg-black bg-opacity-80 px-2 py-0.5 text-xs rounded text-white">
                    0:00
                  </span>
                </div>
              </div>
              <div className={suggestedStyles.infoContainer}>
                <h4 className={suggestedStyles.title}>
                  {videoTitle || "Video Title"}
                </h4>
                <div className={suggestedStyles.metadata}>
                  {channelName || "Channel Name"}
                </div>
                <div className={suggestedStyles.metadata}>
                  0 views • 0 hours ago
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="bg-[#1A1A1F] p-4 rounded-lg">
      <h3 className="text-lg font-medium mb-4">{title}</h3>
      {getPreviewContent()}
    </div>
  );
};
